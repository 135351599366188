import "./App.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Organogram from "./components/Organogram";
import Cards from "./components/cards";
import Popup from "./components/Popup";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "./components/slider";
import "./assets/css/responsive.css";
import ImageSlider from "./components/slider";
import SwiperComponent from "./components/slider";
import FloatingButton from "./components/Floatingbutton";
import "./assets/css/responsive.css";
import JoinUs from "./components/JoinUs";
import WebFont from "webfontloader";
import "./CSS/webstyle.css";
// import './assets/css/style.css';
// import './assets/css/style.css';

function App() {
  const items = [
    { id: 1, title: "Languages", content: "Languages content" },
    { id: 2, title: "Computer Skills", content: "Computer Skills content" },
    { id: 3, title: "Creativity", content: "Creativity content" },
    {
      id: 4,
      title: "Modern Technologies and Industrial Needs",
      content:
        "Modern Technologies and Industrial Needs content.Modern Technologies and Industrial Needs content.Modern Technologies and Industrial Needs content.Modern Technologies and Industrial Needs content",
    },
    {
      id: 5,
      title: "Industrial Linkage",
      content: "Industrial Linkage content",
    },
  ];

  const [activePopup, setActivePopup] = useState(null);
  const [activeTeamMember, setActiveTeamMember] = useState(null);

  const handleItemClick = (item) => {
    setActivePopup(item);
  };
  const handleTeamMemberClick = (member) => {
    setActiveTeamMember(member);
  };

  const handleCloseClick = () => {
    setActivePopup(null);
    setActiveTeamMember(null);
  };

  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Crimson Pro:300,500"],
      },
    });
  }, []);
  return (
    <>
      {/* <div class="page-wrapper"> */}
      <div className={` ${activePopup ? "blurred" : ""}`}>
        <Navbar />
        <FloatingButton />
        {/* <style
          dangerouslySetInnerHTML={{
            __html:
              "\n    body {\n        -webkit-font-smoothing: antialiased;\n        -moz-font-smoothing: antialiased;\n        -o-font-smoothing: antialiased;\n    }\n    ",
          }}
        /> */}

        <section className="section">
          <div class="banner-wrapper">
            <section class="banner-one banner-carousel__one no-dots owl-theme owl-carousel 75vh ">
              <div class="banner-one__slide banner-one__slide-two 75vh ">
                {/* <main className="hero-content"> */}
                <main className="d-flex">
                  {/* <div className=" hero internal ">
                    <div className="hero-section-content"> */}
                  <div className="hero-section-content-block internal bg-light-blue col-lg-6 col-12">
                    <div className="wrapper ">
                      {" "}
                      {/* max-width-640-mobile-480 */}
                      <h1 className=" margin-bottom-28 mt-5">
                        Igniting Innovation and Cultivating a Passion for
                        Invention
                      </h1>
                      <p className="regular-xl max-width-420">
                        With comprehensive facilities, dedicated faculty, and
                        hands-on learning experiences, we empower students to
                        pioneer breakthroughs in technology. Join us embark on a
                        journey where knowledge meets skills to be in step with
                        ever changing Industrial needs.
                      </p>
                    </div>
                    <a href="/contact-us" className="button primary w-button">
                      Contact Us
                    </a>
                  </div>
                  <div className="hero-section-image internal about d-none d-lg-block" />
                  {/* <Slider/> */}
                  {/* </div>
                  </div> */}
                </main>
              </div>
              <div class="banner-one__slide banner-one__slide-one">
                <div class="container">
                  <div class="banner-one__bubble-1"></div>
                  <div class="banner-one__bubble-2"></div>
                  <div class="banner-one__bubble-3"></div>
                  <img
                    src={require("./assets/images/slider-1-scratch.png")}
                    alt=""
                    className="banner-one__scratch "
                  />
                  <img
                    src={require("./assets/images/slider-1-person-1.png")}
                    class="banner-one__person d-lg-block d-none"
                    alt=""
                  />
                  <div className="d-flex flex-column">
                    <div class="row no-gutters">
                      <div class="col-xl-12">
                        <h3
                          class="banner-one__title banner-one__light-color"
                          style={{ fontFamily: "unset" }}
                        >
                          We Can <br></br>
                          Teach You
                        </h3>
                        <p class="banner-one__tag-line">
                          are you ready to learn?
                        </p>
                        {/* <a href="#" class="thm-btn banner-one__btn">
                          Learn More
                        </a> */}
                      </div>
                    </div>
                    {/* <img
                      src={require("./assets/images/slider-1-person-1.png")}
                      class="banner-one__person d-none-lg d-block"
                      alt=""
                    /> */}
                  </div>
                </div>
              </div>
              <div class="banner-one__slide banner-one__slide-two 75vh ">
                {/* <main className="hero-content"> */}
                <main className="d-flex">
                  {/* <div className=" hero internal ">
                    <div className="hero-section-content"> */}
                  <div className="hero-section-content-block internal bg-light-blue col-lg-6 col-12">
                    <div className="wrapper ">
                      {" "}
                      {/* max-width-640-mobile-480 */}
                      <h1 className=" margin-bottom-28 mt-5">
                        Igniting Innovation and Cultivating a Passion for
                        Invention
                      </h1>
                      <p className="regular-xl max-width-420">
                        With comprehensive facilities, dedicated faculty, and
                        hands-on learning experiences, we empower students to
                        pioneer breakthroughs in technology. Join us embark on a
                        journey where knowledge meets skills to be in step with
                        ever changing Industrial needs.
                      </p>
                    </div>
                    <Link to="/ContactUs" className="button primary w-button">
                      Contact Us
                    </Link>
                  </div>
                  <div className="hero-section-image internal about d-none d-lg-block" />
                  {/* <Slider/> */}
                  {/* </div>
                  </div> */}
                </main>
              </div>
              <div class="banner-one__slide banner-one__slide-one">
                <div class="container">
                  <div class="banner-one__bubble-1"></div>
                  <div class="banner-one__bubble-2"></div>
                  <div class="banner-one__bubble-3"></div>
                  <img
                    src={require("./assets/images/slider-1-scratch.png")}
                    alt=""
                    className="banner-one__scratch "
                  />
                  <img
                    src={require("./assets/images/slider-1-person-1.png")}
                    class="banner-one__person d-lg-block d-none"
                    alt=""
                  />
                  <div className="d-flex flex-column">
                    <div class="row no-gutters">
                      <div class="col-xl-12">
                        <h3
                          class="banner-one__title banner-one__light-color"
                          style={{ fontFamily: "unset" }}
                        >
                          We Can <br></br>
                          Teach You
                        </h3>
                        <p class="banner-one__tag-line">
                          are you ready to learn?
                        </p>
                        {/* <a href="#" class="thm-btn banner-one__btn">
                          Learn More
                        </a> */}
                      </div>
                    </div>
                    {/* <img
                      src={require("./assets/images/slider-1-person-1.png")}
                      class="banner-one__person d-none-lg d-block"
                      alt=""
                    /> */}
                  </div>
                </div>
              </div>
            </section>
            <div class="banner-carousel-btn">
              <a href="#" class="banner-carousel-btn__left-btn">
                <i class="kipso-icon-left-arrow"></i>
              </a>
              <a href="#" class="banner-carousel-btn__right-btn">
                <i class="kipso-icon-right-arrow"></i>
              </a>
            </div>
            {/* <div class="banner-one__cta">
                <div class="banner-one__cta-icon">
                    <i class="kipso-icon-black-graduation-cap-tool-of-university-student-for-head"></i>
                    
                </div>
                <div class="banner-one__cta-title">
                    <h3 class="banner-one__cta-text"><a href="#">Read how we encourage our
                            students to learn</a></h3>
                </div>
                <div class="banner-one__cta-link">
                    <a href="#"><i class="kipso-icon-right-arrow"></i></a>
                </div>
            </div> */}
          </div>
          {/* <main className="hero-content">
            <div className="w-layout-blockcontainer container hero internal w-container">
              <div className="hero-section-content">
                <div className="hero-section-content-block internal bg-light-blue">
                  <div className="wrapper max-width-640-mobile-480">
                    <h1 className=" margin-bottom-28">
                      Igniting Innovation and Cultivating a Passion for
                      Invention
                    </h1>
                    <p className="regular-xl max-width-420">
                      With comprehensive facilities, dedicated faculty, and
                      hands-on learning experiences, we empower students to
                      pioneer breakthroughs in technology. Join us embark on a
                      journey where knowledge meets skills to be in step with
                      ever changing Industrial needs.
                    </p>
                  </div>
                  <a href="/contact-us" className="button primary w-button">
                    Contact Us
                  </a>
                </div>
                <div className="hero-section-image internal about" />
                 <Slider/> 
              </div>
            </div>
          </main> */}
        </section>

        <section class="about-two">
          <div class="container">
            <div class="row">
              <div class="col-xl-6">
                <div class="about-two__content">
                  <div class="block-title text-left">
                    <h2 class="block-title__title">
                      Welcome to <br></br>
                      <span>SKANS</span> Institute of Technology
                    </h2>
                  </div>
                  <p class="about-two__text">
                    SKANS holds a distinguished reputation in the realm of
                    education, a legacy that dates back to 1991. With campuses
                    located in key cities such as Lahore, Islamabad, Rawalpindi,
                    Sialkot, Faisalabad, Multan, Gujranwala, and Peshawar, we
                    strive to offer excellence in education. Our strong
                    partnerships with prestigious local and international
                    institutions and universities underscore our commitment to
                    providing top-tier educational opportunities
                  </p>
                  <div class="about-two__single-wrap">
                    <div class="about-two__single">
                      <div class="about-two__single-icon">
                        <i class="kipso-icon-professor"></i>
                      </div>
                      <div class="about-two__single-content">
                        <p class="about-two__single-text">
                          Start learning from our experts
                        </p>
                      </div>
                    </div>
                    <div class="about-two__single">
                      <div class="about-two__single-icon">
                        <i class="kipso-icon-knowledge"></i>
                      </div>
                      <div class="about-two__single-content">
                        <p class="about-two__single-text">
                          <i class="bi bi-bank2 text-dark"></i>Enhance your
                          skills with us now
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <a href="#" class="thm-btn">Learn More</a> */}
                </div>
              </div>
              <div class="col-xl-6 d-flex justify-content-xl-end justify-content-sm-center">
                <div class="about-two__image">
                  <span class="about-two__image-dots"></span>
                  <img
                    src={require("./Photos/sit(2).jpg")}
                    alt=""
                    className="img-fluid"
                  />
                  {/* <div class="about-two__count">
                    <div class="about-two__count-text">
                      <i class="bi bi-bank2 text-dark"></i>
                      Years of Success
                      <span class="counter">35+</span>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          {/* margin-top-64-tablet-48-mobile-24 */}
          <div className="w-layout-blockcontainer container w-container">
            <div className="banner-type-two bg-purple">
              <div className="wrapper max-width-950-tablet-640-mobile-480">
                <p className="banner-heading">
                  In the new technological world order, skills are the
                  reflection of knowledge.
                </p>
              </div>
              <div className="wrapper">
                <div className="line black margin-bottom-12" />
                {/* <div className="regular-l">The motto of SIT</div> */}
              </div>
            </div>
          </div>
        </section>
        <section className="section mt-5">
          {/* margin-top-176-mobile-144 */}
          <div className="container ">
            <div className="d-flex flex-wrap justify-content-around">
              <p className="col-lg-6 col-12 lh-lg text-justify">
                <h1 className=" margin-bottom-32-tablet-24 fs-1 ">
                  Discover SIT
                </h1>
                Welcome to Skans Institute of Technology (SIT), where skills
                change lives. At SIT, our vision is to empower tomorrow's
                leaders in innovation and inventions through specialized
                technical education.<br></br> <br></br>
                We offer high-quality, industry-aligned DAE programs in
                Mechanical technology, Electrical Technology, Civil Technology,
                and Computer Information Technology (CIT), with additional
                programs in Software Technology and Electronics Technology
                starting from August 2025. We are affiliated with the Federal
                Board of Secondary and Intermediate Education (FBISE) and in the
                process with Punjab Board of Technical Education (PBTE) and
                Punjab Skill Development Association (PSDA).<br></br> <br></br>
              </p>
              <div className="wrapper col-lg-5 col-12 mt-3">
                <b className="fs-5 ">Our Distinguishing Feature</b>
                <main class="new-main-container">
                  <ol class="new-gradient-list">
                    <li class="new-list-item">Integrated DAE Programs</li>
                    <li class="new-list-item">
                      Affiliations and associations with international and local
                      universities.
                    </li>
                    <li class="new-list-item">
                      Extended Support in the form of Job Fairs, Industrial
                      Internships and training
                    </li>
                    <li class="new-list-item">
                      Industrial Advisory Board of well reputed industrialists
                      and educationists to review and update curriculum as per
                      contemporary needs.
                    </li>
                  </ol>
                </main>
              </div>
            </div>
          </div>
        </section>

        <section className="">
          <div className="w-layout-blockcontainer container w-container">
            <div className="w-layout-grid images-grid">
              <img
                src={require("./Photos/lab2.jpg")}
                loading="lazy"
                id="w-node-_5ac82391-55b3-01fa-bf8f-8dc3df011d6e-05cd89f9"
                sizes="(max-width: 479px) 90vw, (max-width: 767px) 94vw, (max-width: 991px) 90vw, 92vw"
                alt="SIT"
                srcSet={require("./Photos/lab2.jpg")}
                className="images-grid-image-1"
              />
              <img
                src={require("./Photos/lab3.jpg")}
                loading="lazy"
                id="w-node-_799666a1-2eba-c7b5-74e0-d943ddb227a1-05cd89f9"
                sizes="(max-width: 479px) 90vw, (max-width: 767px) 94vw, (max-width: 991px) 90vw, 92vw"
                alt="School clasroom"
                srcSet={require("./Photos/lab3.jpg")}
                className="images-grid-image-2"
              />
              <img
                src={require("./Photos/lab5.jpg")}
                loading="lazy"
                id="w-node-fc93beb0-fac9-c8cf-7a00-31901a7a2bfe-05cd89f9"
                sizes="(max-width: 479px) 90vw, (max-width: 767px) 94vw, (max-width: 991px) 90vw, 92vw"
                alt="School park"
                srcSet={require("./Photos/lab5.jpg")}
                className="images-grid-image-3"
              />
            </div>
          </div>
        </section>
        {/* <section className="section margin-top-176-mobile-144">
          <div className="w-layout-blockcontainer container w-container">
            <h1 className=" max-width-500-mobile-320 fw-bold margin-bottom-80-tablet-56">
              How We Meet The Needs of Students
            </h1>

            <div className="d-flex flex-wrap justify-content-around">
              <div className="col-lg-5 col-12">
                <img
                  src={require("./Photos/home_2.webp")}
                  loading="lazy"
                  alt="SIT"
                  srcSet={require("./Photos/home_2.webp")}
                  className="images-grid-image-5"
                />
              </div>
              <div className="col-lg-6 col-12">
                <article>
                  <dl className="d-flex flex-column">
                    {items.map((item) => (
                      <Link to="/" key={item.id}>
                        <dt onClick={() => handleItemClick(item)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            fill="currentColor"
                            className="bi bi-caret-right-fill darkblue"
                            viewBox="0 0 16 16"
                          >
                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                          </svg>
                          &nbsp;&nbsp;&nbsp;&nbsp;{item.title}
                        </dt>
                      </Link>
                    ))}
                  </dl>
                </article>
                {activePopup && (
                  <>
                    <Popup
                      isActive={!!activePopup}
                      title={activePopup.title}
                      content={activePopup.content}
                      onClose={handleCloseClick}
                    />
                    <div className="overlay" onClick={handleCloseClick}></div>
                  </>
                )}
              </div>
            </div>
          </div>
        </section> */}

        <section class="section mt-5">
          {/* margin-top-176-mobile-144 */}
          <div class="w-layout-blockcontainer container w-container">
            <h2 class="h2 max-width-432-mobile-320 margin-bottom-56">
            How to meet the needs of our students?
            </h2>
            <div class="w-layout-grid blocks-grid-2-tablet-1-mobile-1">
              <div
                // id="w-node-c828a7b6-aa92-7063-9419-21ff500cb565-05cd89f9"
                class="block meetstudenthover padding-24-32"
              >
                {/* <i class="fa fa-language  margin-bottom-32 fs-1" aria-hidden="true"></i> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-translate icon-size-40 margin-bottom-32"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286zm1.634-.736L5.5 3.956h-.049l-.679 2.022z" />
                  <path d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zm7.138 9.995q.289.451.63.846c-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6 6 0 0 1-.415-.492 2 2 0 0 1-.94.31" />
                </svg>
                <div class="wrapper max-width-640 width-100">
                  <h4 class="h4 margin-bottom-24">Languages</h4>
                  <div class="line black margin-bottom-20"></div>
                  <p class="text-justify">
                    Students with potential will receive language classes to
                    prepare for international job opportunities and projects
                    like CPEC. They will aim for fluency in these languages,
                    especially Level 4 proficiency in Chinese for accessing
                    scholarships in China, with full assistance and coordination
                    from SIT.
                  </p>
                </div>
              </div>
              <div class="block padding-24-32 meetstudenthover">
                {/* <img
                  src="https://assets-global.website-files.com/65e1c0c2fd61a5053f5c7bc9/65e1ddacf43bf8efd2eeab83_Book.svg"
                  loading="lazy"
                  alt=""
                  class="icon-size-40 margin-bottom-32"
                /> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-pc-display icon-size-40 margin-bottom-32"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 1a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1zm1 13.5a.5.5 0 1 0 1 0 .5.5 0 0 0-1 0m2 0a.5.5 0 1 0 1 0 .5.5 0 0 0-1 0M9.5 1a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zM9 3.5a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 0-1h-5a.5.5 0 0 0-.5.5M1.5 2A1.5 1.5 0 0 0 0 3.5v7A1.5 1.5 0 0 0 1.5 12H6v2h-.5a.5.5 0 0 0 0 1H7v-4H1.5a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5H7V2z" />
                </svg>
                <div class="wrapper max-width-640 width-100">
                  <h4 class="h4 margin-bottom-24">Computer Skills</h4>
                  <div class="line black margin-bottom-20"></div>
                  <p class=" text-justify">
                    All students will be exposed to the use of computers at a
                    working level through practical training. Good students will
                    be given advanced training. AutoCAD and the use of other
                    software will be taught to these advanced students.
                    Additionally, they will learn how to use the internet for
                    business activities and earning professionally.
                  </p>
                </div>
              </div>
              <div class="block padding-24-32 meetstudenthover">
                {/* bg-light-yellow-green */}
                <img
                  src="https://cdn-icons-png.flaticon.com/512/1126/1126453.png"
                  loading="lazy"
                  alt=""
                  class="icon-size-40 margin-bottom-32"
                />
                <div class="wrapper max-width-640 width-100">
                  <h4 class="h4 margin-bottom-24">Creativity</h4>
                  <div class="line black margin-bottom-20"></div>
                  <p class="text-justify">
                    Enhanced through hobby activities in a club-like fashion and
                    through projects, those with potential will be incubated
                    under faculty supervision. The best projects will be
                    transformed into prototypes or products. This applies to
                    both physical and software projects.
                  </p>
                </div>
              </div>
              <div class="block padding-24-32 meetstudenthover">
                <img
                  src="https://cdn-icons-png.flaticon.com/128/11366/11366161.png"
                  loading="lazy"
                  alt=""
                  style={{ width: "50px" }}
                  class=" margin-bottom-32"
                />
                <div class="wrapper max-width-640 width-100 px-0">
                  <h4 class="h4 margin-bottom-24 ">
                    Modern Technologies & Industrial Needs
                  </h4>
                  <div class="line black margin-bottom-20"></div>
                  <p class="text-justify">
                    The curriculum includes Computer-Aided Design and
                    Manufacturing, 3D Printing Techniques, Industrial Automation
                    and Electronics, Solar Technology, HVAC, Automotive
                    Technology, Robotics and AI, and GIS Technology.
                  </p>
                </div>
              </div>

              <div class="block padding-24-32 meetstudenthover">
                <img
                  src="https://cdn-icons-png.flaticon.com/128/9552/9552474.png"
                  loading="lazy"
                  alt=""
                  style={{ width: "50px" }}
                  class=" margin-bottom-32"
                />
                <div class="wrapper max-width-640 width-100">
                  <h4 class="h4 margin-bottom-24">Methodology for Teaching</h4>
                  <div class="line black margin-bottom-20"></div>
                  <p class="text-justify">
                    The program operates within the framework of accrediting
                    authorities and is practically focused. Outstanding students
                    will be selected for high-level skill courses. All students
                    will be placed on internships in the industry. Each year,
                    students will engage in group project-based learning,
                    supervised by faculty, with an emphasis on innovation and
                    invention.
                  </p>
                </div>
              </div>

              <div class="block padding-24-32 meetstudenthover">
                <img
                  src={require("./Photos/curriculumicon.png")}
                  loading="lazy"
                  alt=""
                  style={{ width: "50px" }}
                  class="margin-bottom-32"
                />
                <div class="wrapper max-width-640 width-100">
                  <h4 class="h4 margin-bottom-24">
                    Extra-Curricular Activities
                  </h4>
                  <div class="line black margin-bottom-20"></div>
                  <p class="text-justify">
                    Indoor games will be provided, and outdoor matches will be
                    organized periodically. There will be tours and visits,
                    debates and declamations, and quiz competitions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="">
          <div>
            <h1 className="mt-5 fw-bold container max-width-500-mobile-320 margin-bottom-56">
              Our Team
            </h1>

            <div className="container w-layout-grid cards-grid-3-tablet-1-mobile-1">
              <div
                id="w-node-ed8d4416-89d1-80ca-6079-dae76e0d01f6-05cd89f9"
                className="person-card"
              >
                <div className="person-image-wrapper">
                  <img
                    src={require("./Photos/avatar.png")}
                    loading="lazy"
                    sizes="(max-width: 479px) 90vw, (max-width: 767px) 94vw, (max-width: 991px) 90vw, 92vw"
                    srcSet={require("./Photos/avatar.png")}
                    alt="Anna Patejczuk"
                    className="person-image"
                  />
                </div>
                <div className="wrapper">
                  <div className="d-flex justify-content-between">
                    <h4 className="h4 margin-bottom-16">Talat Ali Khan</h4>
                    <button class="button-7 mb-3" role="button">
                      View Detail
                    </button>
                  </div>
                  <div className="line black margin-bottom-16" />
                  <div className="regular-l"> Director SIT</div>
                </div>
              </div>
              <div className="person-card">
                <div className="person-image-wrapper">
                  <img
                    src={require("./Photos/avatar.png")}
                    loading="lazy"
                    sizes="(max-width: 479px) 90vw, (max-width: 767px) 94vw, (max-width: 991px) 90vw, 92vw"
                    srcSet={require("./Photos/avatar.png")}
                    alt="Kevin Morrison"
                    className="person-image"
                  />
                </div>
                <div className="wrapper">
                  <div className="d-flex justify-content-between">
                    <h4 className="h4 margin-bottom-16">M. Naweed Hassan </h4>
                    <button class="button-7 mb-3" role="button">
                      View Detail
                    </button>
                  </div>

                  <div className="line black margin-bottom-16" />
                  <div className="regular-l">Principal SIT</div>
                </div>
              </div>
              <div className="person-card">
                <div className="person-image-wrapper">
                  <img
                    src={require("./Photos/avatar.png")}
                    loading="lazy"
                    sizes="(max-width: 479px) 90vw, (max-width: 767px) 94vw, (max-width: 991px) 90vw, 92vw"
                    srcSet={require("./Photos/avatar.png")}
                    alt="Jack Dixon"
                    className="person-image"
                  />
                </div>
                <div className="wrapper">
                  <div className="d-flex justify-content-between">
                    <h4 className="h4 margin-bottom-16">M Tanwess Awan</h4>
                    <button class="button-7 mb-3" role="button">
                      View Detail
                    </button>
                  </div>
                  <div className="line black margin-bottom-16" />
                  <div className="regular-l"> HoD Electronics</div>
                </div>
              </div>
              <div className="person-card">
                <div className="person-image-wrapper">
                  <img
                    src={require("./Photos/avatar.png")}
                    loading="lazy"
                    sizes="(max-width: 479px) 90vw, (max-width: 767px) 94vw, (max-width: 991px) 90vw, 92vw"
                    srcSet={require("./Photos/avatar.png")}
                    alt="German Morales"
                    className="person-image"
                  />
                </div>
                <div className="wrapper">
                  <div className="d-flex justify-content-between">
                    <h4 className="h4 margin-bottom-16">Engr. Maria Zafar </h4>

                    <button class="button-7 mb-3" role="button">
                      View Detail
                    </button>
                  </div>
                  <div className="line black margin-bottom-16" />
                  <div className="regular-l">
                    HoD Computer Information Technology and Incharge Academics
                  </div>
                </div>
              </div>
              <div className="person-card">
                <div className="person-image-wrapper">
                  <img
                    src={require("./Photos/avatar.png")}
                    loading="lazy"
                    sizes="(max-width: 479px) 90vw, (max-width: 767px) 94vw, (max-width: 991px) 90vw, 92vw"
                    srcSet={require("./Photos/avatar.png")}
                    alt="Anna Demchenko"
                    className="person-image"
                  />
                </div>
                <div className="wrapper">
                  <div className="d-flex justify-content-between">
                    <h4 className="h4 margin-bottom-16">Engr Abdul Rashid </h4>

                    <button class="button-7 mb-3" role="button">
                      View Detail
                    </button>
                  </div>
                  <div className="line black margin-bottom-16" />
                  <div className="regular-l">HoD Electrical Technology</div>
                </div>
              </div>
              <div className="person-card">
                <div className="person-image-wrapper">
                  <img
                    src={require("./Photos/avatar.png")}
                    loading="lazy"
                    sizes="(max-width: 479px) 90vw, (max-width: 767px) 94vw, (max-width: 991px) 90vw, 92vw"
                    srcSet={require("./Photos/avatar.png")}
                    alt="Anna Demchenko"
                    className="person-image"
                  />
                </div>
                <div className="wrapper">
                  <div className="d-flex justify-content-between">
                    <h4 className="h4 margin-bottom-16">Mazahir Hussain </h4>

                    <button class="button-7 mb-3" role="button">
                      View Detail
                    </button>
                  </div>
                  <div className="line black margin-bottom-16" />
                  <div className="regular-l">HoD Civil Technology</div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <JoinUs />

        <Footer />
      </div>
      {/* </div> */}
    </>
  );
}
export default App;
