import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import "../CSS/AdmissionForm.css"
import FloatingButton from '../components/Floatingbutton'

function EnquiryForm() {
  return (
    <>
     <Navbar/>
     <FloatingButton/>
            <div class="login-root">
                <div class="box-root flex-flex flex-direction--column" style={{ minHeight: "100vh", flexGrow: '1' }}>
                    <div className="loginbackground box-background--white padding-top--64" style={{zIndex: 0}}>
                        <div className="loginbackground-gridContainer">
                            <div className="box-root flex-flex" style={{ gridArea: 'top/start/end/1' }}>
                                <div className="box-root" style={{ background: 'linear-gradient(white 0%, rgb(247, 250, 252) 33%)', flexGrow: '1' }}></div>
                            </div>
                            <div className="box-root flex-flex" style={{ gridArea: '4/2/auto/5' }}>
                                <div className="box-root box-divider--light-all-2 animationLeftRight tans3s" style={{ flexGrow: '1' }}></div>
                            </div>
                            <div className="box-root flex-flex" style={{ gridArea: '6/start/auto/2' }}>
                                <div className="box-root box-background--blue800" style={{ flexGrow: '1' }}></div>
                            </div>
                            <div className="box-root flex-flex" style={{ gridArea: '7/start/auto/4' }}>
                                <div className="box-root box-background--blue animationLeftRight" style={{ flexGrow: '1' }}></div>
                            </div>
                            <div className="box-root flex-flex" style={{ gridArea: '8/4/auto/6' }}>
                                <div className="box-root box-background--gray100 animationLeftRight tans3s" style={{ flexGrow: '1' }}></div>
                            </div>
                            <div className="box-root flex-flex" style={{ gridArea: '2/15/auto/end' }}>
                                <div className="box-root box-background--cyan200 animationRightLeft tans4s" style={{ flexGrow: '1' }}></div>
                            </div>
                            <div className="box-root flex-flex" style={{ gridArea: '3/14/auto/end' }}>
                                <div className="box-root box-background--blue animationRightLeft" style={{ flexGrow: '1' }}></div>
                            </div>
                            <div className="box-root flex-flex" style={{ gridArea: '4/17/auto/20' }}>
                                <div className="box-root box-background--gray100 animationRightLeft tans4s" style={{ flexGrow: '1' }}></div>
                            </div>
                            <div className="box-root flex-flex" style={{ gridArea: '5/14/auto/17' }}>
                                <div className="box-root box-divider--light-all-2 animationRightLeft tans3s" style={{ flexGrow: '1' }}></div>

                            </div>
                        </div>
                    </div>
                    <div class="box-root padding-top--24 flex-flex flex-direction--column mt-5" style={{ flexGrow: '1', zIndex: 9 }}>
                        
                        <div class="formbg-outer">
                            <div class="box-root padding-top--48 padding-bottom--24 flex-flex flex-justifyContent--center mt-3">
                            <h1 className='heading1'><a className='anchor' href="" rel="dofollow">INQUIRY FORM</a></h1>
                        </div>
                            <div class="formbg">
                                <div class="formbg-inner padding-horizontal--48">
                                    <span class="padding-bottom--15 span1">Please fill in the below field</span>
                                    <form id="stripe-login">
                                        <div class="field padding-bottom--15">
                                            <label className='label1' for="name">Name</label>
                                            <input type="text" name="name" />
                                        </div>
                                        <div class="field padding-bottom--15">
                                            <label className='label1' for="DOB">DOB</label>
                                            <input type="date" name="DOB" />
                                        </div>
                                        <div class="field padding-bottom--15">
                                            <label className='label1' for="gender">Gender</label>
                                            <input type="text" name="gender" />
                                        </div>
                                        <div class="field padding-bottom--15">
                                            <label className='label1' for="Mobile">Mobile No.</label>
                                            <input type="text" name="Mobile" />
                                        </div>
                                        
                                        <label className='label1' for="name">Qualification</label>
                                        <div className='d-flex'>
                                        <label class="label1 particles-checkbox-container">
                                            <input type="radio" class="particles-checkbox" name="toggle" checked/>
                                            <span>Matric</span>
                                        </label>

                                        <label class=" label1 particles-checkbox-container">
                                            <input type="radio" class="particles-checkbox" name="toggle" />
                                            <span>Intermediate</span>
                                        </label>
                                        </div>
                                        {/* <div class="field padding-bottom--15">
                                            <div class="grid--50-50">
                                                <label className='label1' for="password">Password</label>
                                                <div class="reset-pass">
                                                    <a href="#">Forgot your password?</a>
                                                </div>
                                            </div>
                                            <input type="password" name="password" />
                                        </div> */}
                                        <div class="field padding-bottom--24">
                                            <label className='label1' for="email">Email</label>
                                            <input type="email" name="email" />
                                        </div>

                                        <div class="field padding-bottom--24">
                                            <input type="submit" name="submit" value="Apply Now" />
                                        </div>

                                    </form>
                                </div>
                            </div>

                        </div>
                        <Footer />
                    </div>
                    
                </div> 
                
            </div>

           
            
    </>
  )
}

export default EnquiryForm