import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import JoinUs from "../../components/JoinUs";
import Footer from "../../components/Footer";
import FloatingButton from "../../components/Floatingbutton";
import { Link } from "react-router-dom";
import Profiles from "./Profiles";

function Administeration() {
  const teamMembers = [
    {
      id: 1,
      photo: require('../../Photos/HOD_Mechanical.png'),
      name: "M. Naweed Hassan",
      position: "Principal SIT",
      detail: "M Naweed Hassan, a mechanical engineering graduate from UET Lahore in 1982, embarked on a distinguished career path. Beginning at the Heavy Rebuild Factory Taxila, he transitioned to the Corps of Electrical and Mechanical Engineering of the Pak Army, accumulating 25 years of service with diverse assignments and professional training both domestically and internationally. His impactful roles encompassed instructional duties at a military college for six years and leadership in the Tank Al Khalid Development project for nearly two decades, involving design, trials, and production, alongside international engagements. Following his retirement in 2010, he joined NUST as a faculty member in the Department of Mechanical Engineering for eleven years, playing a pivotal role in establishing and managing the Manufacturing Resource Centre. With four decades of engineering expertise, M Naweed Hassan has left a lasting imprint in the engineering realm.",
    },
    {
      id: 2,
      photo: require('../../Photos/HOD_Electrical.jpg'),
      name: "M Tanwess Awan",
      position: "HoD Electronics",
      detail: "Muhammad Tanwees Awan has a DAE in Ground Signaling Technology from the School of Electronics, Pakistan Air Force, and a master's in Political Science from The University of Punjab. He's been the Director of an International School & College since 2021, with technical courses from institutes like PTCL & PAF. With 15 years of experience in managing technical units and a focus on educating out-of-school children, he's making a significant impact in the education sector",
    },
    {
      id: 3,
      photo: require('../../Photos/HOD_CIT.jpg'),
      name: "Engr. Maria Zafar",
      position: "HoD Computer Information Technology and Incharge Academics",
      detail: "Engr Maria Zafar, a Computer Engineering graduate from UET Taxila, has held diverse roles in the educational sector. His experience ranges from serving as an IT Coordination Officer to Vice Principal and eventually Principal in a college. Specializing in industrial robotics, her career reflects a dedication to technological advancement and educational leadership.",
    },
    {
      id: 4,
      photo: require('../../Photos/HOD_Civil.jpg'),
      name: "Mazahir Hussain",
      position: "HoD Civil Technology",
      detail: "Mr. Mazahir Hussain, a distinguished professional with a B.Tech in Civil Technology and an MA in Education, possesses a remarkable track record in the field. With a decade as HoD Civil Technology at Karakuram College of Commerce and Technology in Skardu, along with significant roles as Vice Principal and various instructional positions, his expertise in civil engineering and education shines through. His diverse experience includes international exposure, such as instructing in Sharjah, UAE, and contributing to the National Vocational and Technical Training Commission in Pakistan.",
    },
    {
      id: 5,
      photo: require('../../Photos/HOD_Mechanical.png'),
      name: "M. Naweed Hassan",
      position: "HoD Mechanical",
      detail: "M Naweed Hassan is a mechanical engineering graduate from UET Lahore. He has working experience in Heavy Rebuild Factory Taxila and Corps of Electrical and Mechanical Engineering of the Pakistan Army. His impactful roles encompassed instructional duties at a military college for six years and leadership in the Tank Al Khalid Development project for nearly two decades. Following his retirement in 2010, he joined NUST as a faculty member in the Department of Mechanical Engineering for eleven years, playing a pivotal role in establishing and managing the Manufacturing Resource Centre.",
    },
  ];

  const [selectedTeamMember, setSelectedTeamMember] = useState(null);

  const handleTeamMemberClick = (member) => {
    setSelectedTeamMember(member);
    console.log("This is selectedTeamMember",selectedTeamMember)
  };

  useEffect(() => {
    console.log("This is selectedTeamMember", selectedTeamMember);
  }, [selectedTeamMember]);
  // const handleCloseDetails = () => {
  //   setSelectedTeamMember(null);
  // };

  return (
    <>
      <Navbar />
      <FloatingButton />
      <section className="section py-3">
        <main className="hero-content">
          <div className="w-layout-blockcontainer container hero internal w-container">
            <div className="hero-section-content">
              <div className="hero-section-content-block internal bg-light-red">
                <div className="wrapper max-width-640-mobile-480">
                  <h1 className="margin-bottom-28">
                    Meet Our Visionary Leaders
                    {/* Our Leaders, Your Guides */}
                  </h1>
                  <p className="regular-xl max-width-420">
                    Explore the profiles of our Principal, HoDs, and dedicated
                    team at SKANS Institute of Technology.
                  </p>
                </div>
              </div>
              <div className="hero-section-image internal advanced-program"></div>
            </div>
          </div>
        </main>
      </section>
      <section className="section pt-0">
        <div className="container">
          <h1 className="my-5">Team Members</h1>
          <div className="container w-layout-grid cards-grid-3-tablet-1-mobile-1">
            {teamMembers.map((member) => (
              <div className="person-card" key={member.id}>
                <div className="person-image-wrapper">
                  <img
                    src={member.photo}
                    loading="lazy"
                    // sizes="(max-width: 479px) 60vw, (max-width: 767px) 64vw, (max-width: 991px) 60vw, 62vw"
                    srcSet={member.photo}
                    alt={member.name}
                    className="person-image"
                  />
                </div>
                <div className="">
                  <div className="d-flex justify-content-between">
                    <h4 className="h4 margin-bottom-16">{member.name}</h4>
                    <Link
                      className="button-7 mb-3 rounded-5 px-4"
                      to={`/profile/${member.id}`}
                    >
                      View Detail
                    </Link>
                  </div>
                  <div className="line black margin-bottom-16" />
                  <div className="regular-l">{member.position}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="mt-5 container lh-lg">
        <h2 className="pb-2">
          Admission Office
        </h2>
        <p>
        The admission office at our esteemed technical Institute exudes a sense of warm hospitality, embodying a welcoming environment for all visitors. Its meticulous organization ensures a seamless and efficient process for prospective students. The ambiance fosters a conducive environment for academic pursuits, setting the stage for a successful educational endeavor. Enter and experience the embrace of a well-structured and inviting space.
        </p>
      </section>

      <section className="mt-5 container lh-lg">
        <h2 className="pb-2">
        Coordination
        </h2>
        <p>
        The coordination office at our esteemed technical Institute epitomizes precision and efficiency, orchestrating all activities with meticulous care. It serves as the cornerstone of seamless operations, ensuring every detail aligns harmoniously. Within its walls, a culture of structured coordination thrives, guaranteeing a cohesive and effective workflow. Step into this hub of excellence and witness the artistry of flawless coordination in action.
        </p>
      </section>

      <section className="mt-5 container lh-lg">
        <h2 className="pb-2">
        Examination
        </h2>
        <p>
        The examination office at our tech Institute is like a guardian of academic integrity, ensuring fairness and accuracy in assessments. It operates with precision, overseeing exams with meticulous attention to detail. Within its walls, a culture of excellence and accountability flourishes, guiding students towards success.
        </p>
      </section>
 

      <JoinUs />
      <Footer />
    </>
  );
}

export default Administeration;
