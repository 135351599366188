import React from 'react'
import Navbar from '../components/Navbar'
import FloatingButton from '../components/Floatingbutton'
import JoinUs from '../components/JoinUs'
import Footer from '../components/Footer'

function Projects() {
  return (
    <>
    <Navbar/>
    <FloatingButton/>
    <section className="section py-3">
        <main className="hero-content">
          <div className=" container hero internal">
            <div className="hero-section-content">
              <div className="hero-section-content-block internal bg-light-red">
                <div className="wrapper max-width-640-mobile-480">
                  <h1 className="margin-bottom-28">
                  Empowering Educators for Excellence
                    {/* Our Leaders, Your Guides */}
                  </h1>
                  <p className="regular-xl max-width-420">
                  Enhance teaching standards and foster holistic growth in a transformative 6-week program
                  </p>
                </div>
              </div>
              <div className="hero-section-image internal Library-background"></div>
            </div>
          </div>
        </main>
      </section>
    <section className='container lh-lg mt-5'>
    <h2 className='mb-3'>Teachers Training Program</h2>

<p>The teachers training program conducted at our institute was a pivotal initiative aimed at enhancing the quality of our teaching staff to ensure they meet the highest standards. Through comprehensive workshops and professional development sessions, our educators were equipped with the latest teaching methodologies and tools. This program underscores our commitment to providing students with top-tier education and fostering a culture of continuous improvement among our faculty.  It was 6 weeks program that not only catered the education paradigm but also focused of personality development of the instructor as well the student.</p>

    </section>

    <JoinUs/>
    <Footer/>
    </>
  )
}

export default Projects