import React, { useEffect, useState } from "react";
import "../App.css";
import "../components/Navbar.css";
import { Link } from "react-router-dom";
import "../CSS/sidenav.css";
import Dropdown1 from "./Dropdown";

const NavbarCareer = () => {
  // jkjkljlk
  const [menuOpen, setMenuOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [subMenuOpenIndex, setSubMenuOpenIndex] = useState(null);
  const [aboutDropdownOpen, setAboutDropdownOpen] = useState(false);

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const dropdownItems = [
    {
      id: 1,
      text: "Programs",
      subItems: [
        "DAE Mechanical",
        "DAE Electrical",
        "DAE Civil",
        "DAE CIT",
        "Vocational Programs",
      ],
    },
    {
      id: 2,
      text: "Fee Structure",
    },
    {
      id: 3,
      text: "Library",
    },
  ];

  const AdmissiondropdownItems = [
    {
      id: 1,
      text: "Admission Procedure",
    },
    {
      id: 2,
      text: "Inquiry form",
    },
  ];

  const AboutDropdown = [
    {
      id: 1,
      text: "Administration",
    },
    {
      id: 2,
      text: "Facts",
    },
    {
      id: 3,
      text: "Campus",
    },
    {
      id: 4,
      text: "Core Values",
    },
    {
      id: 5,
      text: "Partner Schools",
    },
    {
      id: 6,
      text: "Affiliations",
    },
  ];
  const NewsdropdownItems = [
    {
      id: 1,
      text: "Functions",
    },
    {
      id: 2,
      text: "Few videos",
    },
    {
      id: 3,
      text: "Partner’s school feedback",
    },
    {
      id: 3,
      text: "Projects",
    },
  ];

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSubMenuMouseEnter = (index) => {
    setSubMenuOpenIndex(index);
  };

  const handleSubMenuMouseLeave = () => {
    setSubMenuOpenIndex(null);
  };

  const toggleAboutDropdown = () => {
    setAboutDropdownOpen(!aboutDropdownOpen);
  };

  const currentYear = new Date().getFullYear();

  return (
    <>
      <nav className="nav pt-0 pb-1">
        <div className="container-fluid d-md-block d-none ">
          <div className=" d-flex flex-row-reverse   bg-white ">
            <div className="d-flex align-items-center">
              <a href="/contact-us" className=" button-71 my-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="currentColor"
                  class="bi bi-check2-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0" />
                  <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
                </svg>{" "}
                Claim your free
                <span style={{ fontSize: "12px" }}> career counseling</span>
              </a>

              <a
                href="mailto: info@sit.pk"
                className="nav-menu-item mb-1 fw-bold"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  fill="currentColor"
                  class="bi bi-envelope-at-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M2 2A2 2 0 0 0 .05 3.555L8 8.414l7.95-4.859A2 2 0 0 0 14 2zm-2 9.8V4.698l5.803 3.546zm6.761-2.97-6.57 4.026A2 2 0 0 0 2 14h6.256A4.5 4.5 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586zM16 9.671V4.697l-5.803 3.546.338.208A4.5 4.5 0 0 1 12.5 8c1.414 0 2.675.652 3.5 1.671" />
                  <path d="M15.834 12.244c0 1.168-.577 2.025-1.587 2.025-.503 0-1.002-.228-1.12-.648h-.043c-.118.416-.543.643-1.015.643-.77 0-1.259-.542-1.259-1.434v-.529c0-.844.481-1.4 1.26-1.4.585 0 .87.333.953.63h.03v-.568h.905v2.19c0 .272.18.42.411.42.315 0 .639-.415.639-1.39v-.118c0-1.277-.95-2.326-2.484-2.326h-.04c-1.582 0-2.64 1.067-2.64 2.724v.157c0 1.867 1.237 2.654 2.57 2.654h.045c.507 0 .935-.07 1.18-.18v.731c-.219.1-.643.175-1.237.175h-.044C10.438 16 9 14.82 9 12.646v-.214C9 10.36 10.421 9 12.485 9h.035c2.12 0 3.314 1.43 3.314 3.034zm-4.04.21v.227c0 .586.227.8.581.8.31 0 .564-.17.564-.743v-.367c0-.516-.275-.708-.572-.708-.346 0-.573.245-.573.791" />
                </svg>{" "}
                &nbsp;&nbsp; <span className="mb-1">hr@skansinstituteoftechnology.pk</span>
              </a>

              <a href="tel:923325224538" className="nav-menu-item fw-bold ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="currentColor"
                  class="bi bi-telephone-fill mb-1"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                  />
                </svg>
                &nbsp;&nbsp; <span className="mb-1">+92 332 5224538</span>
              </a>

              <a href="https://www.instagram.com/skanstec" className="nav-menu-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  fill="currentColor"
                  class="bi bi-instagram mb-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                </svg>
              </a>

              <a href="https://www.facebook.com/skanstechh" className="nav-menu-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-facebook mb-1 "
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
                </svg>
              </a>
              <Link to="/ContactUs" className="nav-menu-item">
                Contact Us
              </Link>
            </div>
            <div></div>
          </div>
        </div>
        <div className="w-layout-blockcontainer container w-container">
          <div className="nav-wrapper pt-1">
            <a href="/" className="nav-logo-wrapper w-inline-block">
              <div className="nav-logo-text">
                <img width={"90%"} src={require("../Photos/sit-logo3.jpg")} alt="Logo" />
              </div>
            </a>
            <div className="nav-menu-items-wrapper">
              
              <a href="/" className="nav-menu-item">
                Home
              </a>
              <div className="dropdown">
                <div
                  className="nav-menu-dropdown-toggle "
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  About&nbsp;&nbsp;
                  <img
                    src="https://assets-global.website-files.com/65e1c0c2fd61a5053f5c7bc9/65e1ddac4533a353a8527ef7_Caret%20Down.svg"
                    alt=""
                    className="icon-size-16"
                  />
                </div>

                <ul
                  className="dropdown-menu border border-0 shadow-sm"
                  aria-labelledby="dropdownMenuButton"
                  data-bs-auto-close="outside"
                  style={{ width: "490px" }}
                >
                  <span className="d-flex flex-nowrap align-items-center p-2">
                    <li className="col-4  ps-2">
                      <img
                        className="img-fluid w-75"
                        src={require("../Photos/sit-logo1.png")}
                        alt="Logo"
                      />
                    </li>
                    <li className=" nav-about-column nav-about-image">
                      <Link to="/Administration" className="dropdown-item">
                      Administration 
                      </Link>
                      <Link to="/Facts" className="dropdown-item">
                        Facts
                      </Link>
                      <Link to="/Campus" className="dropdown-item">
                        Campus
                      </Link>
                    </li>
                    <li className="nav-about-column">
                      <Link to="/Core-Value" className="dropdown-item">
                        Core Values
                      </Link>
                      <Link to="/Partner-Schools" className="dropdown-item">
                        Partners schools
                      </Link>
                      <Link to="/Affiliations" className="dropdown-item">
                        Affiliations
                      </Link>
                    </li>
                  </span>
                </ul>
              </div>

              {/* <div className="d-flex justify-content-center">
                <div className="dropdown">
                  <button
                    className="btn btn-primary "
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Dropdown button{" "}
                    <img
                    src="https://assets-global.website-files.com/65e1c0c2fd61a5053f5c7bc9/65e1ddac4533a353a8527ef7_Caret%20Down.svg"
                    alt=""
                    className="icon-size-16"
                  />
                  </button>
                  <ul
                    className="dropdown-menu "
                    aria-labelledby="dropdownMenuButton"
                  >
                    <li>
                      <a className="dropdown-item" href="#">
                        Action
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Another action
                      </a>
                    </li>
                    <li className="dropdown-submenu">
                      <a className="dropdown-item dropdown-toggle" href="#">
                        Submenu
                      </a>
                      <ul className="dropdown-menu">
                        <li>
                          <a className="dropdown-item" href="#">
                            Submenu item 1
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            Submenu item 2
                          </a>
                        </li>
                        <li className="dropdown-submenu">
                          <a className="dropdown-item dropdown-toggle" href="#">
                            Submenu item 3
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a className="dropdown-item" href="#">
                                Multi level 1
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item" href="#">
                                Multi level 2
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            Submenu item 4
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            Submenu item 5
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div> */}
              {/* <div
                className="nav-menu-dropdown"
                onMouseEnter={toggleDropdown}
                onMouseLeave={toggleDropdown}
              >
                <div className="nav-menu-dropdown-toggle">
                  Academics
                  <img
                    src="https://assets-global.website-files.com/65e1c0c2fd61a5053f5c7bc9/65e1ddac4533a353a8527ef7_Caret%20Down.svg"
                    alt=""
                    className="icon-size-16"
                  />
                </div>
                {isOpen && (
                  <div className="nav-menu-dropdown-list">
                    {dropdownItems.map((item, index) => (
                      <div
                        key={item.id}
                        className="nav-menu-item-drop"
                        onMouseEnter={() => handleSubMenuMouseEnter(index)}
                        onMouseLeave={handleSubMenuMouseLeave}
                      >
                        {item.subItems ? (
                          <>
                            {item.text}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <img
                              src="https://assets-global.website-files.com/65e1c0c2fd61a5053f5c7bc9/65e1ddac4533a353a8527ef7_Caret%20Down.svg"
                              alt=""
                              className="icon-size-16"
                            />
                            {subMenuOpenIndex === index && (
                              <div className="nav-menu-dropdown-submenu">
                                {item.subItems.map((subItem, subIndex) => (
                                  <Link
                                    key={subIndex}
                                    to={`/${subItem
                                      .toLowerCase()
                                      .replace(/\s/g, "-")}`}
                                    className="nav-menu-item-subdrop"
                                  >
                                    {subItem}
                                  </Link>
                                ))}
                              </div>
                            )}
                          </>
                        ) : (
                          <Link
                            to={`/${item.text
                              .toLowerCase()
                              .replace(/\s/g, "-")}`}
                          >
                            {item.text}
                          </Link>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div> */}
              <div className="d-flex justify-content-center">
                <div className="dropdown">
                  <div
                    className="nav-menu-dropdown-toggle "
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Academics{" "}
                    <img
                      src="https://assets-global.website-files.com/65e1c0c2fd61a5053f5c7bc9/65e1ddac4533a353a8527ef7_Caret%20Down.svg"
                      alt=""
                      className="icon-size-16"
                    />
                  </div>
                  <ul
                    className="dropdown-menu border border-0 shadow-sm"
                    aria-labelledby="dropdownMenuButton"
                    data-bs-auto-close="outside"
                  >
                    {dropdownItems.map((item, subIndex) => (
                      <li
                        key={item.id}
                        className={item.subItems ? "dropdown-submenu" : ""}
                      >
                        {item.subItems ? (
                          <>
                            <Link key={subIndex} className="dropdown-item">
                              {item.text}{" "}&nbsp;&nbsp;
                              <img
                                src="https://assets-global.website-files.com/65e1c0c2fd61a5053f5c7bc9/65e1ddac4533a353a8527ef7_Caret%20Down.svg"
                                alt=""
                                className="icon-size-16"
                              />
                            </Link>
                            <ul className="dropdown-menu border border-0 shadow-sm">
                              {item.subItems.map((subItem, index) => (
                                <li key={index}>
                                  <Link
                                    className="dropdown-item"
                                    to={`/${subItem
                                      .toLowerCase()
                                      .replace(/\s/g, "-")}`}
                                  >
                                    {subItem}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </>
                        ) : (
                          <Link
                            className="dropdown-item"
                            to={`/${item.text
                              .toLowerCase()
                              .replace(/\s/g, "-")}`}
                          >
                            {item.text}
                          </Link>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              {/* <div
                className="nav-menu-dropdown"
                onMouseEnter={toggleDropdown}
                onMouseLeave={toggleDropdown}
              >
                <Link className="nav-menu-dropdown-toggle">
                  Admissions
                  <img
                    src="https://assets-global.website-files.com/65e1c0c2fd61a5053f5c7bc9/65e1ddac4533a353a8527ef7_Caret%20Down.svg"
                    alt=""
                    className="icon-size-16"
                  />
                </Link>
                {isOpen && (
                  <div className="nav-menu-dropdown-list">
                    {AdmissiondropdownItems.map((item, index) => (
                      <Link
                        key={index}
                        className="nav-menu-item-drop"
                        onMouseEnter={() => handleSubMenuMouseEnter(index)}
                        onMouseLeave={handleSubMenuMouseLeave}
                        to={`/${item.text.toLowerCase().replace(/\s/g, "-")}`}
                      >
                        {item.subItems ? (
                          <>
                            {item.text}
                            {subMenuOpenIndex === index && (
                              <div className="nav-menu-dropdown-submenu">
                                {item.subItems &&
                                  item.subItems.map((subItem, subIndex) => (
                                    <Link
                                      key={subIndex}
                                      to={`/${subItem
                                        .toLowerCase()
                                        .replace(/\s/g, "-")}`}
                                      className="nav-menu-item-subdrop"
                                    >
                                      {subItem}
                                    </Link>
                                  ))}
                              </div>
                            )}
                          </>
                        ) : (
                          <Link
                            to={`/${item.text
                              .toLowerCase()
                              .replace(/\s/g, "-")}`}
                          >
                            {item.text}
                          </Link>
                        )}
                      </Link>
                    ))}
                  </div>
                )}
              </div> */}
              <div className="d-flex justify-content-center">
                <div className="dropdown">
                  <div
                    className="nav-menu-dropdown-toggle "
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Admissons{" "}
                    <img
                      src="https://assets-global.website-files.com/65e1c0c2fd61a5053f5c7bc9/65e1ddac4533a353a8527ef7_Caret%20Down.svg"
                      alt=""
                      className="icon-size-16"
                    />
                  </div>
                  <ul
                    className="dropdown-menu border border-0 shadow-sm"
                    aria-labelledby="dropdownMenuButton"
                    data-bs-auto-close="outside"
                  >
                    {AdmissiondropdownItems.map((item, subIndex) => (
                      <li
                        key={item.id}
                        className={item.subItems ? "dropdown-submenu" : ""}
                      >
                        {item.subItems ? (
                          <>
                            <Link
                              key={subIndex}
                              to={`/${item.text
                                .toLowerCase()
                                .replace(/\s/g, "-")}`}
                              className="nav-menu-item-subdrop"
                            >
                              {item.text}{" "}
                              <img
                                src="https://assets-global.website-files.com/65e1c0c2fd61a5053f5c7bc9/65e1ddac4533a353a8527ef7_Caret%20Down.svg"
                                alt=""
                                className="icon-size-16"
                              />
                            </Link>
                            <ul className="dropdown-menu border border-0 shadow-sm">
                              {item.subItems.map((subItem, index) => (
                                <li key={index}>
                                  <Link
                                    className="dropdown-item"
                                    to={`/${subItem
                                      .toLowerCase()
                                      .replace(/\s/g, "-")}`}
                                  >
                                    {subItem}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </>
                        ) : (
                          <Link
                            className="dropdown-item"
                            to={`/${item.text
                              .toLowerCase()
                              .replace(/\s/g, "-")}`}
                          >
                            {item.text}
                          </Link>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <Link to="/career" className="nav-menu-item">
                Career
              </Link>

              {/* <div
                className="nav-menu-dropdown"
                onMouseEnter={toggleDropdown}
                onMouseLeave={toggleDropdown}
              >
                <div className="nav-menu-dropdown-toggle">
                  News & Events
                  <img
                    src="https://assets-global.website-files.com/65e1c0c2fd61a5053f5c7bc9/65e1ddac4533a353a8527ef7_Caret%20Down.svg"
                    alt=""
                    className="icon-size-16"
                  />
                </div>
                {isOpen && (
                  <div className="nav-menu-dropdown-list">
                    {NewsdropdownItems.map((item, index) => (
                      <div
                        key={item.id}
                        className="nav-menu-item-drop"
                        onMouseEnter={() => handleSubMenuMouseEnter(index)}
                        onMouseLeave={handleSubMenuMouseLeave}
                      >
                        {item.subItems ? (
                          <>
                            {item.text}
                            {subMenuOpenIndex === index && (
                              <div className="nav-menu-dropdown-submenu">
                                {item.subItems &&
                                  item.subItems.map((subItem, subIndex) => (
                                    <Link
                                      key={subIndex}
                                      to={`/${subItem
                                        .toLowerCase()
                                        .replace(/\s/g, "-")}`}
                                      className="nav-menu-item-subdrop"
                                    >
                                      {subItem}
                                    </Link>
                                  ))}
                              </div>
                            )}
                          </>
                        ) : (
                          <Link
                            to={`/${item.text
                              .toLowerCase()
                              .replace(/\s/g, "-")}`}
                          >
                            {item.text}
                          </Link>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div> */}

              <div className="d-flex justify-content-center">
                <div className="dropdown">
                  <div
                    className="nav-menu-dropdown-toggle "
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    News & Events{" "}
                    <img
                      src="https://assets-global.website-files.com/65e1c0c2fd61a5053f5c7bc9/65e1ddac4533a353a8527ef7_Caret%20Down.svg"
                      alt=""
                      className="icon-size-16"
                    />
                  </div>
                  <ul
                    className="dropdown-menu border border-0 shadow-sm"
                    aria-labelledby="dropdownMenuButton"
                    data-bs-auto-close="outside"
                  >
                    {NewsdropdownItems.map((item, subIndex) => (
                      <li
                        key={item.id}
                        className={item.subItems ? "dropdown-submenu" : ""}
                      >
                        {item.subItems ? (
                          <>
                            <Link
                              key={subIndex}
                              to={`/${item.text
                                .toLowerCase()
                                .replace(/\s/g, "-")}`}
                              className="nav-menu-item-subdrop"
                            >
                              {item.text}{" "}
                              <img
                                src="https://assets-global.website-files.com/65e1c0c2fd61a5053f5c7bc9/65e1ddac4533a353a8527ef7_Caret%20Down.svg"
                                alt=""
                                className="icon-size-16"
                              />
                            </Link>
                            <ul className="dropdown-menu border border-0 shadow-sm">
                              {item.subItems.map((subItem, index) => (
                                <li key={index}>
                                  <Link
                                    className="dropdown-item"
                                    to={`/${subItem
                                      .toLowerCase()
                                      .replace(/\s/g, "-")}`}
                                  >
                                    {subItem}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </>
                        ) : (
                          <Link
                            className="dropdown-item"
                            to={`/${item.text
                              .toLowerCase()
                              .replace(/\s/g, "-")}`}
                          >
                            {item.text}
                          </Link>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <a href="/blog" className="nav-menu-item">
                Blog
              </a>
              <Link to="/Noticeboard" className="nav-menu-item">
                Noticeboard
              </Link>
            </div>

            <div className="menu-icon-wrapper">
              <label for="drop" onClick={handleMenuToggle}>
                <img
                  src={
                    "https://assets-global.website-files.com/65e1c0c2fd61a5053f5c7bc9/65e1ddacd6ea5efbf21e94d2_Hamburger%20Menu.svg"
                  }
                  alt="Menu Icon"
                  className="icon-size-28 d-block"
                />
              </label>
            </div>
          </div>
        </div>

        <div
          className={`overflow-auto nav-adaptation  ${menuOpen ? "open" : ""}`}
        >
          <div className=" overflow-auto nav-adaptation-content ">
            <div className="nav-adaptation-links overflow-auto">
              {/* <div
                className="nav-menu-dropdown"
                onMouseEnter={toggleDropdown}
                onMouseLeave={toggleDropdown}
              >
                <div className="nav-menu-dropdown-toggle ps-0 regular-xl">
                  About
                  <img
                    src="https://assets-global.website-files.com/65e1c0c2fd61a5053f5c7bc9/65e1ddac4533a353a8527ef7_Caret%20Down.svg"
                    alt=""
                    className="icon-size-16"
                  />
                </div>
                {isOpen && (
                  <div className="nav-menu-dropdown-list">
                    {AboutDropdown.map((item, index) => (
                      <div
                        key={item.id}
                        className="nav-menu-item-drop"
                        onMouseEnter={() => handleSubMenuMouseEnter(index)}
                        onMouseLeave={handleSubMenuMouseLeave}
                      >
                        {item.subItems ? (
                          <>
                            {item.text}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <img
                              src="https://assets-global.website-files.com/65e1c0c2fd61a5053f5c7bc9/65e1ddac4533a353a8527ef7_Caret%20Down.svg"
                              alt=""
                              className="icon-size-16"
                            />
                            {subMenuOpenIndex === index && (
                              <div className="nav-menu-dropdown-submenu">
                                {item.subItems.map((subItem, subIndex) => (
                                  <Link
                                    key={subIndex}
                                    to={`/${subItem
                                      .toLowerCase()
                                      .replace(/\s/g, "-")}`}
                                    className="nav-menu-item-subdrop"
                                  >
                                    {subItem}
                                  </Link>
                                ))}
                              </div>
                            )}
                          </>
                        ) : (
                          <Link
                            to={`/${item.text
                              .toLowerCase()
                              .replace(/\s/g, "-")}`}
                          >
                            {item.text}
                          </Link>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div> */}

              <a href="/" className="nav-adaptation-link">
                Home
              </a>

              <div className="dropdown">
                <div
                  className="nav-menu-dropdown-toggle  ps-0 regular-xl"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  About{" "}
                  <img
                    src="https://assets-global.website-files.com/65e1c0c2fd61a5053f5c7bc9/65e1ddac4533a353a8527ef7_Caret%20Down.svg"
                    alt=""
                    className="icon-size-16"
                  />
                </div>
                <ul
                  className="dropdown-menu border border-0 shadow-sm"
                  aria-labelledby="dropdownMenuButton"
                  data-bs-auto-close="outside"
                >
                  {AboutDropdown.map((item, subIndex) => (
                    <li
                      key={item.id}
                      className={item.subItems ? "dropdown-submenu" : ""}
                    >
                      {item.subItems ? (
                        <>
                          <Link
                            key={subIndex}
                            className="nav-menu-item-subdrop"
                          >
                            {item.text}{" "}
                            <img
                              src="https://assets-global.website-files.com/65e1c0c2fd61a5053f5c7bc9/65e1ddac4533a353a8527ef7_Caret%20Down.svg"
                              alt=""
                              className="icon-size-16"
                            />
                          </Link>
                          <ul className="dropdown-menu border border-0 shadow-sm">
                            {item.subItems.map((subItem, index) => (
                              <li key={index}>
                                <Link
                                  className="dropdown-item"
                                  to={`/${subItem
                                    .toLowerCase()
                                    .replace(/\s/g, "-")}`}
                                >
                                  {subItem}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </>
                      ) : (
                        <Link
                          className="dropdown-item"
                          to={`/${item.text.toLowerCase().replace(/\s/g, "-")}`}
                        >
                          {item.text}
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
              </div>

              {/* <div
                className="nav-menu-dropdown"
                onMouseEnter={toggleDropdown}
                onMouseLeave={toggleDropdown}
              >
                <div className="nav-menu-dropdown-toggle ps-0 regular-xl">
                  Academics
                  <img
                    src="https://assets-global.website-files.com/65e1c0c2fd61a5053f5c7bc9/65e1ddac4533a353a8527ef7_Caret%20Down.svg"
                    alt=""
                    className="icon-size-16"
                  />
                </div>
                {isOpen && (
                  <div className="nav-menu-dropdown-list">
                    {dropdownItems.map((item, index) => (
                      <div
                        key={item.id}
                        className="nav-menu-item-drop"
                        onMouseEnter={() => handleSubMenuMouseEnter(index)}
                        onMouseLeave={handleSubMenuMouseLeave}
                      >
                        {item.subItems ? (
                          <>
                            {item.text} &nbsp;&nbsp;&nbsp;&nbsp;
                            <img
                              src="https://assets-global.website-files.com/65e1c0c2fd61a5053f5c7bc9/65e1ddac4533a353a8527ef7_Caret%20Down.svg"
                              alt=""
                              className="icon-size-16"
                            />
                            {subMenuOpenIndex === index && (
                              <div className="nav-menu-dropdown-submenu">
                                {item.subItems.map((subItem, subIndex) => (
                                  <Link
                                    key={subIndex}
                                    to={`/${subItem
                                      .toLowerCase()
                                      .replace(/\s/g, "-")}`}
                                    className="nav-menu-item-subdrop"
                                  >
                                    {subItem}
                                  </Link>
                                ))}
                              </div>
                            )}
                          </>
                        ) : (
                          <Link
                            to={`/${item.text
                              .toLowerCase()
                              .replace(/\s/g, "-")}`}
                          >
                            {item.text}
                          </Link>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div> */}

              {/* <div className="dropdown">
                  <div
                    className="nav-menu-dropdown-toggle  ps-0 regular-xl"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Academics <img
                    src="https://assets-global.website-files.com/65e1c0c2fd61a5053f5c7bc9/65e1ddac4533a353a8527ef7_Caret%20Down.svg"
                    alt=""
                    className="icon-size-16"
                  />
                  </div>
                  <ul
                    className="dropdown-menu border border-0 shadow-sm"
                    aria-labelledby="dropdownMenuButton"
                    data-bs-auto-close="outside"
                  >
                    {dropdownItems.map((item, subIndex) => (
                      <li
                        key={item.id}
                        className={item.subItems ? "dropdown-submenu" : ""}
                      >
                        {item.subItems ? (
                          <>
                            <Link
                              key={subIndex}
                              className="nav-menu-item-subdrop"
                            >
                              {item.text}{" "}
                              <img
                    src="https://assets-global.website-files.com/65e1c0c2fd61a5053f5c7bc9/65e1ddac4533a353a8527ef7_Caret%20Down.svg"
                    alt=""
                    className="icon-size-16"
                  />
                            </Link>
                            <ul className="dropdown-menu border border-0 shadow-sm">
                              {item.subItems.map((subItem, index) => (
                                <li key={index}>
                                  <Link className="dropdown-item"  
                                  to={`/${subItem
                                .toLowerCase()
                                .replace(/\s/g, "-")}`}>
                                    {subItem}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </>
                        ) : (
                          <Link className="dropdown-item"  to={`/${item.text
                            .toLowerCase()
                            .replace(/\s/g, "-")}`}>
                            {item.text}
                          </Link>
                        )}
                      </li>
                    ))}
                  </ul>
                </div> */}

              <div className="dropdown">
                <div
                  className="nav-menu-dropdown-toggle  ps-0 regular-xl"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Academics{" "}
                  <img
                    src="https://assets-global.website-files.com/65e1c0c2fd61a5053f5c7bc9/65e1ddac4533a353a8527ef7_Caret%20Down.svg"
                    alt=""
                    className="icon-size-16"
                  />
                </div>
                <ul
                  className="dropdown-menu border border-0 shadow-sm"
                  aria-labelledby="dropdownMenuButton"
                  data-bs-auto-close="outside"
                >
                  {dropdownItems.map((item, subIndex) => (
                    <li
                      key={item.id}
                      className={item.subItems ? "dropdown-submenu" : ""}
                    >
                      {item.subItems ? (
                        <>
                          <Link
                            key={subIndex}
                            className="nav-menu-item-subdrop"
                          >
                            {item.text}{" "}
                            <img
                              src="https://assets-global.website-files.com/65e1c0c2fd61a5053f5c7bc9/65e1ddac4533a353a8527ef7_Caret%20Down.svg"
                              alt=""
                              className="icon-size-16"
                            />
                          </Link>
                          <ul className="dropdown-menu border border-0 shadow-sm">
                            {item.subItems.map((subItem, index) => (
                              <li key={index}>
                                <Link
                                  className="dropdown-item"
                                  to={`/${subItem
                                    .toLowerCase()
                                    .replace(/\s/g, "-")}`}
                                >
                                  {subItem}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </>
                      ) : (
                        <Link
                          className="dropdown-item"
                          to={`/${item.text.toLowerCase().replace(/\s/g, "-")}`}
                        >
                          {item.text}
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
              </div>

              {/* <div
                className="nav-menu-dropdown"
                onMouseEnter={toggleDropdown}
                onMouseLeave={toggleDropdown}
              >
                <div className="nav-menu-dropdown-toggle ps-0 regular-xl">
                  Admissions
                  <img
                    src="https://assets-global.website-files.com/65e1c0c2fd61a5053f5c7bc9/65e1ddac4533a353a8527ef7_Caret%20Down.svg"
                    alt=""
                    className="icon-size-16"
                  />
                </div>
                {isOpen && (
                  <div className="nav-menu-dropdown-list">
                    {AdmissiondropdownItems.map((item, index) => (
                      <Link
                        key={index}
                        className="nav-menu-item-drop"
                        onMouseEnter={() => handleSubMenuMouseEnter(index)}
                        onMouseLeave={handleSubMenuMouseLeave}
                        to={`/${item.text.toLowerCase().replace(/\s/g, "-")}`}
                      >
                        {item.subItems ? (
                          <>
                            {item.text} &nbsp;&nbsp;&nbsp;&nbsp;
                            <img
                              src="https://assets-global.website-files.com/65e1c0c2fd61a5053f5c7bc9/65e1ddac4533a353a8527ef7_Caret%20Down.svg"
                              alt=""
                              className="icon-size-16"
                            />
                            {subMenuOpenIndex === index && (
                              <div className="nav-menu-dropdown-submenu">
                                {item.subItems &&
                                  item.subItems.map((subItem, subIndex) => (
                                    <Link
                                      key={subIndex}
                                      to={`/${subItem
                                        .toLowerCase()
                                        .replace(/\s/g, "-")}`}
                                      className="nav-menu-item-subdrop"
                                    >
                                      {subItem}
                                    </Link>
                                  ))}
                              </div>
                            )}
                          </>
                        ) : (
                          <Link
                            to={`/${item.text
                              .toLowerCase()
                              .replace(/\s/g, "-")}`}
                          >
                            {item.text}
                          </Link>
                        )}
                      </Link>
                    ))}
                  </div>
                )}
              </div> */}

              <div className="dropdown">
                <div
                  className="nav-menu-dropdown-toggle  ps-0 regular-xl"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Admissions{" "}
                  <img
                    src="https://assets-global.website-files.com/65e1c0c2fd61a5053f5c7bc9/65e1ddac4533a353a8527ef7_Caret%20Down.svg"
                    alt=""
                    className="icon-size-16"
                  />
                </div>
                <ul
                  className="dropdown-menu border border-0 shadow-sm"
                  aria-labelledby="dropdownMenuButton"
                  data-bs-auto-close="outside"
                >
                  {AdmissiondropdownItems.map((item, subIndex) => (
                    <li
                      key={item.id}
                      className={item.subItems ? "dropdown-submenu" : ""}
                    >
                      {item.subItems ? (
                        <>
                          <Link
                            key={subIndex}
                            className="nav-menu-item-subdrop"
                          >
                            {item.text}{" "}
                            <img
                              src="https://assets-global.website-files.com/65e1c0c2fd61a5053f5c7bc9/65e1ddac4533a353a8527ef7_Caret%20Down.svg"
                              alt=""
                              className="icon-size-16"
                            />
                          </Link>
                          <ul className="dropdown-menu border border-0 shadow-sm">
                            {item.subItems.map((subItem, index) => (
                              <li key={index}>
                                <Link
                                  className="dropdown-item"
                                  to={`/${subItem
                                    .toLowerCase()
                                    .replace(/\s/g, "-")}`}
                                >
                                  {subItem}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </>
                      ) : (
                        <Link
                          className="dropdown-item"
                          to={`/${item.text.toLowerCase().replace(/\s/g, "-")}`}
                        >
                          {item.text}
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
              <Link to="/career" className="nav-adaptation-link">
                Career
              </Link>
              {/* <div
                className="nav-menu-dropdown"
                onMouseEnter={toggleDropdown}
                onMouseLeave={toggleDropdown}
              >
                <div className="nav-menu-dropdown-toggle regular-xl ps-0">
                  News & Events
                  <img
                    src="https://assets-global.website-files.com/65e1c0c2fd61a5053f5c7bc9/65e1ddac4533a353a8527ef7_Caret%20Down.svg"
                    alt=""
                    className="icon-size-16"
                  />
                </div>
                {isOpen && (
                  <div className="nav-menu-dropdown-list">
                    {NewsdropdownItems.map((item, index) => (
                      <div
                        key={item.id}
                        className="nav-menu-item-drop"
                        onMouseEnter={() => handleSubMenuMouseEnter(index)}
                        onMouseLeave={handleSubMenuMouseLeave}
                      >
                        {item.subItems ? (
                          <>
                            {item.text} &nbsp;&nbsp;&nbsp;&nbsp;
                            <img
                              src="https://assets-global.website-files.com/65e1c0c2fd61a5053f5c7bc9/65e1ddac4533a353a8527ef7_Caret%20Down.svg"
                              alt=""
                              className="icon-size-16"
                            />
                            {subMenuOpenIndex === index && (
                              <div className="nav-menu-dropdown-submenu">
                                {item.subItems &&
                                  item.subItems.map((subItem, subIndex) => (
                                    <Link
                                      key={subIndex}
                                      to={`/${subItem
                                        .toLowerCase()
                                        .replace(/\s/g, "-")}`}
                                      className="nav-menu-item-subdrop"
                                    >
                                      {subItem}
                                    </Link>
                                  ))}
                              </div>
                            )}
                          </>
                        ) : (
                          <Link
                            to={`/${item.text
                              .toLowerCase()
                              .replace(/\s/g, "-")}`}
                          >
                            {item.text}
                          </Link>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div> */}
              <div className="dropdown">
                <div
                  className="nav-menu-dropdown-toggle  ps-0 regular-xl"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  News & Events{" "}
                  <img
                    src="https://assets-global.website-files.com/65e1c0c2fd61a5053f5c7bc9/65e1ddac4533a353a8527ef7_Caret%20Down.svg"
                    alt=""
                    className="icon-size-16"
                  />
                </div>
                <ul
                  className="dropdown-menu border border-0 shadow-sm"
                  aria-labelledby="dropdownMenuButton"
                  data-bs-auto-close="outside"
                >
                  {NewsdropdownItems.map((item, subIndex) => (
                    <li
                      key={item.id}
                      className={item.subItems ? "dropdown-submenu" : ""}
                    >
                      {item.subItems ? (
                        <>
                          <Link
                            key={subIndex}
                            className="nav-menu-item-subdrop"
                          >
                            {item.text}{" "}
                            <img
                              src="https://assets-global.website-files.com/65e1c0c2fd61a5053f5c7bc9/65e1ddac4533a353a8527ef7_Caret%20Down.svg"
                              alt=""
                              className="icon-size-16"
                            />
                          </Link>
                          <ul className="dropdown-menu border border-0 shadow-sm">
                            {item.subItems.map((subItem, index) => (
                              <li key={index}>
                                <Link
                                  className="dropdown-item"
                                  to={`/${subItem
                                    .toLowerCase()
                                    .replace(/\s/g, "-")}`}
                                >
                                  {subItem}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </>
                      ) : (
                        <Link
                          className="dropdown-item"
                          to={`/${item.text.toLowerCase().replace(/\s/g, "-")}`}
                        >
                          {item.text}
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
              <Link to="/blogs" className="nav-adaptation-link">
                Blog
              </Link>
              <Link to="/Noticeboard" className="nav-adaptation-link">
                Noticeboard
              </Link>
              <Link to="/ContactUs" className="nav-adaptation-link">
                Contact Us
              </Link>
            </div>
            <div className="footer-legal">
              <div className="regular-s color-grey-80">
                © {currentYear}, All Rights Reserved
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavbarCareer;
