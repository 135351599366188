import React from 'react'
import Navbar from '../components/Navbar'
import FloatingButton from '../components/Floatingbutton'
import JoinUs from '../components/JoinUs'
import Footer from '../components/Footer'

function Campus() {
  return (
    <>
    <Navbar/>
    <FloatingButton/>

    <section className="section py-3">
        <main className="hero-content">
          <div className=" container hero internal">
            <div className="hero-section-content">
              <div className="hero-section-content-block internal bg-light-red">
                <div className="wrapper max-width-640-mobile-480">
                  <h1 className="margin-bottom-28">
                  Empowering Success with Premier Facilities
                    {/* Our Leaders, Your Guides */}
                  </h1>
                  <p className="regular-xl max-width-420">
                  At SIT, we offer cutting-edge amenities like cafeteria, labs, and libraries. Our focus on career counseling and specialized courses equips students for competitive industries, promoting self-employment and global readiness
                  </p>
                </div>
              </div>
              <div className="hero-section-image internal advanced-program"></div>
            </div>
          </div>
        </main>
      </section>
    <div class="container mt-4 lh-lg">
    <h2>Facilities</h2>
    <ul class=" mt-3">
      
      <li class="">Cafeteria inside the building</li>
      <li class="">Hygienic and quality food</li>
      <li class="">Clean filtered drinking water</li>
      <li class="">Indoor games</li>
      <li class="">Advanced and equipped labs</li>
      <li class="">Contemporary library</li>
      <li class="">Free career counseling</li>
      <li class="">Pick & drop facility for female in future</li>
    </ul>
  </div>
  
  <div className="container mt-5">
    <h2 className='mb-3'>Map Location</h2>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d53204.80636778993!2d72.92911291122442!3d33.54557064260562!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38df934e7554ade3%3A0xebef25df5484c84e!2sSKANS%20Institute%20of%20Technology%20(SIT)!5e0!3m2!1sen!2s!4v1720119601649!5m2!1sen!2s" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>

    <div className='mt-5 container lh-lg'>
        <h2 className='mb-3'>HALL MARK OF SIT</h2>
        <ul>
            <li>COVERING THE SYLLABI PROPOSED BY BOARD</li>
            <li>SKILL ENHANCEMENT THROUGH SPECIAL COURSES</li>
            <li>PREPARE STUDENTS TO MEET INDUSTRIAL NEEDS</li>
            <li>COMPETATIVE APPROACH</li>
            <li>ABILITY TO SELF EMPLOY THEMSELVES</li>
        </ul>

    </div>
    <JoinUs/>
    <Footer/>
    </>
  )
}

export default Campus