import React from "react";
import Footer from "../components/Footer";
import JoinUs from "../components/JoinUs";
import Navbar from "../components/Navbar";
import FloatingButton from "../components/Floatingbutton";
import NavbarCareer from "../components/NavbarCareer";

function CareerApply() {
  return (
    <>
      <NavbarCareer />
      <FloatingButton />

      <div
        class="box-root flex-flex flex-direction--column "
        style={{
          minHeight: "100vh",
          flexGrow: "1",
          zIndex: 0,
          overflowX: "hidden",
        }}
      >
        <div className="loginbackground box-background--white padding-top--64">
          <div className="loginbackground-gridContainer">
            <div
              className="box-root flex-flex"
              style={{ gridArea: "top/start/end/1" }}
            >
              <div
                className="box-root"
                style={{
                  background:
                    "linear-gradient(white 0%, rgb(247, 250, 252) 33%)",
                  flexGrow: "1",
                }}
              ></div>
            </div>
            <div
              className="box-root flex-flex"
              style={{ gridArea: "4/2/auto/5" }}
            >
              <div
                className="box-root box-divider--light-all-2 animationLeftRight tans3s"
                style={{ flexGrow: "1" }}
              ></div>
            </div>
            <div
              className="box-root flex-flex"
              style={{ gridArea: "6/start/auto/2" }}
            >
              <div
                className="box-root box-background--blue800"
                style={{ flexGrow: "1" }}
              ></div>
            </div>
            <div
              className="box-root flex-flex"
              style={{ gridArea: "7/start/auto/4" }}
            >
              <div
                className="box-root box-background--blue animationLeftRight"
                style={{ flexGrow: "1" }}
              ></div>
            </div>
            <div
              className="box-root flex-flex"
              style={{ gridArea: "8/4/auto/6" }}
            >
              <div
                className="box-root box-background--gray100 animationLeftRight tans3s"
                style={{ flexGrow: "1" }}
              ></div>
            </div>
            <div
              className="box-root flex-flex"
              style={{ gridArea: "2/15/auto/end" }}
            >
              <div
                className="box-root box-background--cyan200 animationRightLeft tans4s"
                style={{ flexGrow: "1" }}
              ></div>
            </div>
            <div
              className="box-root flex-flex"
              style={{ gridArea: "3/14/auto/end" }}
            >
              <div
                className="box-root box-background--blue animationRightLeft"
                style={{ flexGrow: "1" }}
              ></div>
            </div>
            <div
              className="box-root flex-flex"
              style={{ gridArea: "4/17/auto/20" }}
            >
              <div
                className="box-root box-background--gray100 animationRightLeft tans4s"
                style={{ flexGrow: "1" }}
              ></div>
            </div>
            <div
              className="box-root flex-flex"
              style={{ gridArea: "5/14/auto/17" }}
            >
              <div
                className="box-root box-divider--light-all-2 animationRightLeft tans3s"
                style={{ flexGrow: "1" }}
              ></div>
            </div>
          </div>
        </div>

        <div
          class="box-root padding-top--24 flex-flex flex-direction--column mt-5"
          style={{ flexGrow: "1", zIndex: 0 }}
        >
          <h2 className="my-5 text-center mt-5">Upload your CV here</h2>

          <form id="file-upload-form" class="uploader">
            <input
              id="file-upload"
              type="file"
              name="fileUpload"
              accept="image/*"
            />

            <label for="file-upload" id="file-drag">
              <img id="file-image" src="#" alt="Preview" class="hidden" />
              <div id="start">
                <i class="fa fa-download" aria-hidden="true"></i>
                <div>Select a file or drag here</div>
                <div id="notimage" class="hidden">
                  Please select an image
                </div>
                <span id="file-upload-btn" class="btn btn-primary">
                  Select a file
                </span>
              </div>
              <div id="response" class="hidden">
                <div id="messages"></div>
                <progress class="progress" id="file-progress" value="0">
                  <span>0</span>%
                </progress>
              </div>
            </label>
          </form>
          <JoinUs />
          <Footer />
        </div>
      </div>
    </>
  );
}

export default CareerApply;
